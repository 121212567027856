@font-face {
  font-family: 'Monor';
  font-style:  normal;
  font-weight: 400;
  src: url("../fonts/Monor-Regular.otf") format("opentype");
}

@font-face {
  font-family: 'Monor';
  font-style:  normal;
  font-weight: 600;
  src: url("../fonts/Monor-Bold.otf") format("opentype");
}

@font-face {
  font-family: 'Baskervville';
  font-style:  normal;
  font-weight: 400;
  src: url("../fonts/Baskervville-Regular.woff2") format("woff2"),
       url("../fonts/Baskervville-Regular.woff") format("woff");
}