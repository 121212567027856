@import "fonts";
@import "reset";
@import "media-queries";
@import "variables";
@import "flickity";

/* Global
---------------------------------------------- */
:root {
  font-family: var(--body-font);
  font-size: var(--font-size);
  color: var(--black);
  -webkit-font-smoothing: antialiased;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: var(--white);
  font-size: 0.8rem;
}
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal !important;
}
.center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
.text-align-center {
  text-align: center;
}
.heading-font {
  font-family: var(--heading-font);
  letter-spacing: -0.2rem;
}
.lr-page-padding {
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
}
em {
  font-style: italic;
}

/* Navbar
---------------------------------------------- */
header {
  padding: calc(var(--lr-page-padding) * 0.85) var(--lr-page-padding)
    calc(var(--lr-page-padding) * 0.9) var(--lr-page-padding);
  pointer-events: none;
  font-size: var(--caption-size);
  @include for-small-devices {
    padding: 1rem var(--lr-page-padding);
  }
}
.navbar {
  @include for-phone-only {
    padding-bottom: 0.2rem;
  }
  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__link-wrapper {
    &--for-phone-only {
      @include for-tablet-portrait-up {
        display: none;
      }
    }
    &--for-tablet-portrait-up {
      @include for-phone-only {
        display: none;
      }
    }
  }
  &__link {
    display: block;
    pointer-events: initial;
  }
}
.mobile-menu {
  // margin-bottom: 1rem;
  pointer-events: initial;
  @include for-tablet-portrait-up {
    display: none;
  }
  &__content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 400ms linear, max-height 400ms linear;
    will-change: opacity, max-height;
    display: flex;
    flex-direction: column;
    align-items: end;
    align-content: end;
    &.active {
      opacity: 1;
      max-height: 9999px;
      transition: all 400ms linear;
      will-change: opacity, max-height;
    }
  }
  &__item {
    width: 60%;
    display: flex;
    align-items: center;
    padding: 0.2rem 0;
  }
  &__item:nth-of-type(2) {
    width: 55%;
  }
  &__item:nth-of-type(3) {
    width: 50%;
  }
  &__item:nth-of-type(4) {
    width: 45%;
  }
  &__item:nth-of-type(5) {
    width: 50%;
  }
}
.false-dotted {
  border-bottom: 2px dotted var(--black);
  flex-grow: 1;
  height: 0.7em;
  margin: 0 0.1em;
  display: block;
  &--for-tablet-portrait-up {
    @include for-phone-only {
      display: none;
    }
  }
}

/* Default text
---------------------------------------------- */
.default-text {
  line-height: 1.1em;
  @include for-tablet-portrait-up {
    line-height: 1.2em;
  }
  &--indented-paragraphs p {
    text-indent: 3.4em;
  }
  &--centred-on-mobile {
    @include for-phone-only {
      text-align: center;
    }
  }
  p:not(:last-of-type) {
    margin-bottom: 1em;
  }
}

/* Dotted button
---------------------------------------------- */
.dotted-button {
  display: block;
  padding: 0.5rem 2rem;
  border-top: 2px dotted var(--black);
  border-bottom: 2px dotted var(--black);
  text-align: center;
  font-size: 1rem;
}

/* Home page
---------------------------------------------- */
.masonry-wrapper {
  padding: 0 calc(var(--lr-page-padding) * 0.5);
  @include for-large-tablet-portrait-up {
    padding-bottom: calc(var(--bottom-page-padding) * 0.5);
  }
}
/* clear fix */
.masonry-grid {
  font-size: var(--caption-size);
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  &__sizer,
  &__item {
    width: 100%;
    @include for-tablet-portrait-up {
      width: 50%;
    }
    @include for-large-tablet-portrait-up {
      width: 33.33%;
    }
    @include for-tablet-landscape-up {
      width: 25%;
    }
    @include for-desktop-up {
      width: 20%;
    }
  }
  &__item {
    float: left;
    padding: 0 calc(var(--lr-page-padding) * 0.5) 2rem
      calc(var(--lr-page-padding) * 0.5);
    @include for-large-tablet-portrait-up {
      padding: calc(var(--lr-page-padding) * 0.6);
    }
  }
}
.project-card {
  &__image {
    display: block;
    max-width: 100%;
    margin-bottom: 1rem;
  }
  &__title {
    padding: 0 1rem;
    font-family: var(--heading-font);
    font-weight: bold;
    text-align: center;
  }
  &__date {
    text-align: center;
  }
  &__excerpt {
    margin-top: 0.6rem;
  }
}
.news {
  &__wrapper {
    border: 2px dotted var(--black);
    padding: 1rem;
  }
  &__title {
    text-align: center;
    font-size: 1.2em;
    font-style: italic;
    margin-bottom: 0.7rem;
  }
  &__list {
    margin-left: 0.7rem;
  }
  &__list-item {
    &::marker {
      content: "- ";
      font-size: 1.2em;
    }
    &:not(:last-of-type) {
      margin-bottom: 0.7rem;
    }
    a {
      text-decoration: underline;
    }
  }
}

/* Project page
---------------------------------------------- */
.banner {
  $self: &;
  width: 100%;
  margin-bottom: 2rem;
  @include for-large-tablet-portrait-up {
    margin-bottom: 4rem;
  }
  &--top {
    @include for-large-tablet-portrait-up {
      display: flex;
      justify-content: space-between;
      #{ $self }__date {
        text-align: left;
        margin-right: 1rem;
      }
      .banner__title {
        @include for-large-tablet-portrait-up {
          text-align: inherit;
        }
      }
    }
  }
  &--centered {
    display: flex;
    justify-content: center;
    align-content: center;
    #{ $self }__title__wrapper {
      margin: 20vh 0;
      #{ $self }__date {
        text-align: center;
      }
    }
  }
  &--top-centered {
    text-align: center;
  }
  &__title {
    font-size: var(--large-title-size);
    text-align: center;
  }
  &__date {
    margin-top: 0.5rem;
    font-style: italic;
    text-align: center;
    @include for-large-tablet-portrait-up {
      text-align: inherit;
    }
  }
  &__image {
    width: 100%;
    height: auto;
    margin-top: 1rem;
    @include for-large-tablet-portrait-up {
      width: 30vw;
      max-width: 600px;
      margin-top: 0rem;
    }
  }
}
.project-grid:not(.project-grid--padded) .block-type-image img {
  @include for-small-devices {
    padding: 0 var(--lr-page-padding);
  }
}
.project-grid {
  --columns: 12;
  --gutter: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  @include for-tablet-portrait-up {
    grid-template-columns: repeat(6, 1fr);
  }
  @include for-tablet-landscape-up {
    grid-template-columns: repeat(12, 1fr);
  }
  &--full-width {
    figcaption {
      padding-left: var(--lr-page-padding);
      padding-right: var(--lr-page-padding);
    }
  }
  &--padded {
    padding: 0 var(--lr-page-padding);
    @include for-large-tablet-portrait-up {
      grid-gap: var(--gutter);
    }
  }
  &--margin-top {
    margin-top: 6rem;
  }
  &--margin-bottom {
    margin-bottom: var(--gutter);
    @include for-large-tablet-portrait-up {
      margin-bottom: var(--lr-page-padding);
    }
  }
  & > .column {
    @include for-tablet-portrait-up {
      grid-column: span var(--columns);
    }
    &:not(:last-of-type) {
      margin-bottom: 0rem;
      @include for-large-tablet-portrait-up {
        margin-bottom: 0rem;
      }
    }
  }
  .wide-column figcaption {
    @include for-tablet-portrait-up {
      width: 75%;
    }
  }
  .wide-column .block-type-gallery figcaption {
    @include for-tablet-portrait-up {
      width: 85%;
    }
  }
  figcaption {
    margin-top: 1rem;
  }
}
.blocks {
  .link-type:not(:last-of-type) .dotted-button {
    border-bottom: none;
  }
  .link-type:last-of-type {
    margin-bottom: var(--gutter);
  }
}
.block {
  &-type-supporters {
    .supporters {
      p {
        max-width: 800px;
      }
      &__logo-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
      }
      &__logo-list-item {
        &:not(:last-of-type) {
          margin-right: 60px;
        }
      }
      &__logo {
        height: 30px;
        width: auto;
        margin-top: 1rem;
        @include for-tablet-landscape-up {
          height: 50px;
          margin-top: 1.5rem;
        }
      }
    }
  }
  &-type-quote {
    blockquote {
      font-family: var(--heading-font);
      font-style: italic;
      font-size: 1rem;
      &.centered-text {
        text-align: center;
      }
      &.thin-text {
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    footer {
      margin-top: 0.5rem;
      font-family: var(--body-font);
      font-size: initial;
      font-style: initial;
      em {
        font-style: italic;
      }
    }
  }
  &-type-gallery {
    width: 100vw;
    margin: 0 auto;
    @include for-tablet-portrait-up {
      width: 75vw;
    }
    @include for-tablet-landscape-up {
      width: 50vw;
    }
    .project-carousel {
      width: 100%;
      .project-carousel__cell {
        width: 100%;
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
      .carousel-counter {
        float: right;
      }
    }
    figcaption {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }
  &-type-table {
    .collaborators {
      max-width: 700px;
      &__title {
        font-family: var(--heading-font);
        font-size: calc(var(--small-title-size) / 2);
        margin-bottom: 0.5rem;
      }
      a {
        text-decoration: underline;
      }
    }
  }
  &-type-text {
    font-size: 1rem;
    p:not(:last-of-type) {
      margin-bottom: 1em;
    }
    a {
      text-decoration: underline;
    }
  }
  &-type-heading {
    // padding: 0 var(--lr-page-padding);
    font-size: 2rem;
    font-family: var(--heading-font);
    margin-top: var(--gutter);
    @include for-large-tablet-portrait-up {
      font-size: 1.5rem;
    }
    em {
      font-style: italic;
      font-size: 0.8rem;
      font-family: var(--body-font);
    }
  }
  &-type-vimeo {
    width: 100vw;
    margin: 0 auto;
    @include for-tablet-portrait-up {
      width: 75vw;
    }
    @include for-tablet-landscape-up {
      width: 50vw;
    }
    .banner-artwork-video {
      padding: 56.35% 0 0 0;
      position: relative;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .video-controls .toggle-play {
      cursor: pointer;
    }
    .player-controls {
      padding: 1rem 0;
      align-self: start;
      text-align: center;
      .toggle-play.play {
        cursor: pointer;
        position: relative;
        left: 0;
        display: inline-block;
      }
      .toggle-play.pause {
        cursor: pointer;
        position: relative;
        display: inline-block;
      }
    }
  }
  &-type-text__wrapper {
    &--one {
      p:first-of-type {
        text-indent: 3rem;
      }
    }
    &--three {
      column-gap: var(--lr-page-padding);
      @include for-tablet-portrait-up {
        columns: 2;
      }
      @include for-large-tablet-portrait-up {
        columns: 3;
      }
    }
    &--three-staggered {
      p {
        display: block;
      }
      @include for-tablet-portrait-up {
        p {
          width: 50%;
        }
        p:nth-of-type(2n) {
          margin-left: 50%;
        }
      }
      @include for-large-tablet-portrait-up {
        p {
          width: 33.33%;
        }
        p:nth-of-type(2n) {
          margin-left: 33.33%;
        }
        p:nth-of-type(3),
        p:nth-of-type(7),
        p:nth-of-type(11),
        p:nth-of-type(15) {
          margin-left: 66.66%;
        }
      }
    }
  }
}

/* About page
---------------------------------------------- */
.about-top-flex {
  font-size: var(--caption-size);
  @include for-large-tablet-portrait-up {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
}
.about-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__details-wrapper {
    margin-bottom: 3rem;
  }
  &__detail {
    margin-bottom: 1rem;
  }
}
.about-image {
  margin-top: 2rem;
  img {
    max-width: 250px;
  }
}
.bio {
  margin-top: 2rem;
  @include for-large-tablet-portrait-up {
    margin-top: 0rem;
    width: 44vw;
    max-width: 40rem;
  }
}

/* CV
---------------------------------------------- */
.cv {
  margin-top: 2rem;
  margin-bottom: var(--lr-page-padding);
  @include for-large-tablet-portrait-up {
    width: 60vw;
  }
}
.cv-accordion {
  margin-bottom: 0.5rem;
  &__year {
    flex-shrink: 0;
  }
  &:last-of-type .accordion__content p {
    margin-bottom: 0;
  }
  // li:not(:last-of-type) {
  //   margin-bottom: 1em;
  // }
}

/* CV
---------------------------------------------- */
.accordion {
  $self: &;
  font-size: var(--caption-size);
  &:not(:last-of-type) {
    margin-bottom: 0.1em;
  }
  &__button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    color: var(--black);
    border: none;
    background: none;
    outline: none;
    text-decoration: underline;
    &:hover,
    &:focus {
      cursor: pointer;
    }
    &[aria-expanded="true"] {
      + #{ $self }__content {
        opacity: 1;
        max-height: 9999px;
        transition: all 400ms linear;
        will-change: opacity, max-height;
      }
    }
  }
  &__content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 400ms linear, max-height 400ms linear;
    will-change: opacity, max-height;
  }
  &__content-wrapper {
    margin: 1rem 0;
    display: flex;
    gap: 2rem;
  }
}

/* Shop page
---------------------------------------------- */
.product {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @include for-large-tablet-portrait-up {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    width: 100%;
    align-items: end;
    gap: 1rem;
  }
  &:not(:last-of-type) {
    margin-bottom: 2rem;
    @include for-large-tablet-portrait-up {
      margin-bottom: 1rem;
    }
  }
  @include for-small-devices {
    margin-bottom: 2rem;
  }
  &__image-container {
    width: 100%;
    @include for-large-tablet-portrait-up {
      width: 25vw;
    }
  }
  &__image-carousel {
    width: 100%;
  }
  &__image {
    display: block;
    width: 100%;
    height: auto;
  }
  &__title {
    font-size: 1.5rem;
    @include for-large-tablet-portrait-up {
      font-size: var(--small-title-size);
    }
  }
  &__description {
    margin-top: 0.5rem;
    font-size: var(--caption-size);
  }
  &__extras-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @include for-large-tablet-portrait-up {
      margin-left: auto;
      width: 20vw;
      flex-grow: initial;
    }
  }
  &__extra-images {
    margin-bottom: 1rem;
    display: flex;
    gap: 0.4rem;
    @include for-large-tablet-portrait-up {
      margin-bottom: 0.5rem;
      justify-content: center;
    }
  }
  &__extra-image {
    display: block;
    width: 80px;
    height: 60px;
    object-fit: cover;
  }
}

/* Experiences page
---------------------------------------------- */
.experiences-intro {
  margin-bottom: 2rem;
  font-size: 0.8rem;
  @include for-large-tablet-portrait-up {
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 1rem;
  }
  &__left-side {
    max-width: 32rem;
    @include for-small-devices {
      margin-bottom: 2rem;
    }
  }
  &__right-side {
    @include for-large-tablet-portrait-up {
      width: var(--button-width);
      max-width: var(--button-width);
    }
  }
  &__title {
    font-size: var(--medium-title-size);
    margin-bottom: 0.8rem;
  }
}
.experiences-images {
  margin-bottom: 2rem;
  @include for-large-tablet-portrait-up {
    margin-bottom: 5rem;
  }
  &__carousel-cell {
    &:last-of-type .experiences-images__image {
      padding-right: var(--lr-page-padding);
    }
  }
  &__image {
    height: 40vw;
    width: auto;
    padding: 0 0 0 var(--lr-page-padding);
    @include for-large-tablet-portrait-up {
      height: 30vw;
    }
  }
  &__image-caption {
    width: 45vw;
    margin-top: 0.5rem;
    font-size: var(--caption-size);
    padding: 0 0 0 var(--lr-page-padding);
    @include for-large-tablet-portrait-up {
      width: 35vw;
    }
  }
}
.experiences-workshops {
  margin-bottom: 2rem;
  @include for-large-tablet-portrait-up {
    margin-bottom: 4rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  &__left-side {
    max-width: 32rem;
  }
  &__right-side {
    @include for-small-devices {
      margin-top: 2rem;
    }
    @include for-large-tablet-portrait-up {
      width: var(--button-width);
      max-width: var(--button-width);
    }
  }
}
.workshop {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  font-size: 0.8rem;
  &:not(:last-of-type) {
    margin-bottom: 3rem;
  }
  &__icon {
    font-size: 1.4rem;
  }
  &__title {
    font-size: 1.4rem;
    margin-bottom: 0.8rem;
  }
}

/* Carousel counter
---------------------------------------------- */
.carousel-counter {
  text-align: center;
  margin: 1rem auto;
}
