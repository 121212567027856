/*! Flickity v2.3.0
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  /* cursor: move;
  cursor: -webkit-grab;
  cursor: grab; */
  cursor: e-resize;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  /* cursor: -webkit-grabbing;
  cursor: grabbing; */
  cursor: e-resize;
}

/* flickity fade-in on load */

.flickity.is-hidden {
  opacity: 0;
}

.flickity.flickity-enabled {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

/* flickity-fade */

.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
}

.project-carousel__cell:not(.is-selected) {
  animation: fadeOut ease 0s;
}

.project-carousel__cell.is-selected {
  opacity: 1 !important;
  transition: opacity 0s;
  animation: none;
}

.project-carousel__cel.is-selected {
  z-index: 99;
  opacity: 1 !important;
  animation: fadeIn ease 0s;
}
