/* Variables
---------------------------------------------- */
:root {
  --vh: 1;

  --font-size: 20px;
  --body-font: "Baskervville", serif;
  --heading-font: "Monor", serif;
  --caption-size: 0.8rem;
  --text-size: 1rem;
  --small-title-size: 2rem;
  --medium-title-size: 2.25rem;
  --large-title-size: clamp(2.8rem, 1.6rem + 3.2vw, 4.2rem);

  --black: black;
  --white: white;

  --lr-page-padding: 1.2rem;
  --bottom-page-padding: 4rem;

  --button-width: 20rem;
}

/* Variables for-tablet-portrait-up
---------------------------------------------- */
:root {
  @include for-phone-only {
    --lr-page-padding: 0.6rem;
  }
}
